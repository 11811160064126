import React from 'react';
import dynamic from 'next/dynamic';
import { animated, useSpring } from '@react-spring/web';

import Link from '@ui/components/Link';
import Image from '@ui/components/Image';
import AppBar from '@ui/components/AppBar';
import Toolbar from '@ui/components/Toolbar';
import Box from '@ui/components/Box';
import Text from '@ui/components/Text';
import useScrollTrigger from '@ui/hooks/useScrollTrigger';
import { styled } from '@ui/styles';

import ContentColumn from '@web/components/layout/ContentColumn';
// import Menu from '@web/components/app-layout/Menu';
import { BrandCode } from '@interfaces/index';
import BrandCodeContext from '../BrandCodeContext';
import {
	brands,
	getLogo,
	getLogoSize,
} from '@common-lib/modules/brands/constant';
import clientConfig from '@common-lib/clientConfig';

const Menu = dynamic(() => import('@web/components/app-layout/Menu'));
interface Props {
	showMenu?: boolean;
	window?: () => Window;
	initBrandCode?: BrandCode;
}

const getBrandColor = (code?: BrandCode) => {
	switch (code) {
		case BrandCode.KY:
			return 'rgba(255, 255, 255, 0.95)';
		case BrandCode.KYCLINICAL:
			return 'rgba(255, 255, 255, 0.25)';
		case BrandCode.NUAA:
			return 'rgba(255, 255, 255, 0.25)';

		default:
			return 'rgba(255, 255, 255, 0.8999999761581421)';
	}
};
const getBorder = (code?: BrandCode) => {
	switch (code) {
		case BrandCode.AMAMENTE:
			return '1px solid #7389C8';
		case BrandCode.KYCLINICAL:
			return '1px solid #C4878F';
		case BrandCode.KY:
			return '1px solid #DD0734';
		case BrandCode.NUAA:
			return '1px solid #000000';
		default:
			return undefined;
	}
};
const Bar = styled(AppBar, {
	shouldForwardProp: prop => prop !== 'brandCode',
})<{ brandCode?: BrandCode }>(({ theme, elevation, brandCode }) => ({
	height: brandCode ? 'calc(50px + 5rem)' : '5rem',
	[theme.breakpoints.up('sm')]: {
		borderBottom: getBorder(brandCode),
	},
	backgroundColor:
		elevation || brandCode ? getBrandColor(brandCode) : 'transparent',
	backdropFilter: elevation ? 'blur(6px)' : undefined,
	'@media print': {
		display: 'none',
		visibilty: 'hidden',
	},
	// filter: 'blur(8px)',
	// WebkitFilter: 'blur(8px)',
}));

const Tool = styled(Toolbar)(({ theme }) => ({
	minHeight: '5rem !important',
	paddingRight: '0px !important',
	paddingLeft: '0px !important',
}));

const LogoTool = styled(Toolbar)(({ theme }) => ({
	maxHeight: 50,
	minHeight: '50px !important',
	paddingRight: '0px !important',
	paddingLeft: '0px !important',
	backgroundColor: '#ffffff',
	// maxWidth: 1440,
	width: '100%',
	// '& > div': {},
}));
const Content = styled(ContentColumn)(({ theme }) => ({
	[theme.breakpoints.up('sm')]: {
		paddingLeft: theme.spacing(4),
		paddingRight: theme.spacing(4),
	},
}));

const { host } = clientConfig;
const AnimatedLogoTool = animated(LogoTool);
const AnimatedBar = animated(Bar);
const Header: React.FC<React.PropsWithChildren<Props>> = ({
	children,
	showMenu,
	window,
	initBrandCode,
}) => {
	const bCode = React.useContext(BrandCodeContext) || initBrandCode;
	const brandCode = (bCode as any) === 'semina' ? undefined : bCode;
	// Note that you normally won't need to set the window ref as useScrollTrigger
	// will default to window.
	// This is only being set here because the demo is in an iframe.
	const trigger = useScrollTrigger({
		disableHysteresis: true,
		threshold: 0,
		target: window ? window() : undefined,
	});

	const spring = useSpring({
		marginTop: brandCode && trigger ? -50 : 0,
	});
	return (
		<>
			<AnimatedBar
				style={{ height: trigger ? '5rem' : undefined }}
				elevation={trigger ? 4 : 0}
				brandCode={brandCode}
				className="no-print"
				sx={{
					display: 'flex',
					alignItems: 'center',
					flexDirection: 'column',
					// maxWidth: 1440,
					left: 0,
					right: 'auto',
				}}
			>
				{brandCode ? (
					<AnimatedLogoTool style={spring} className="logo-header">
						<Content
							maxWidth={1280}
							className="logo-header-content"
						>
							<Box
								display="flex"
								alignItems="center"
								flexDirection="row"
							>
								{/* <Link href={`${host}/`} passHref legacyBehavior>
									<a> */}
								<Image
									src="/logo_semina.png"
									alt="logo"
									height={18}
									width={108}
									priority
								/>
								{/* </a>
								</Link> */}
								<Text pl={2} fontSize={11} mt={-0.25}>
									{brands.find(m => m.value === brandCode)
										?.label || 'Semina'}{' '}
									é uma marca do grupo Semina
								</Text>
							</Box>
						</Content>
					</AnimatedLogoTool>
				) : null}

				<Tool
					sx={{ maxWidth: 1280, width: '100%' }}
					className="main-header"
				>
					<Content
						display="flex"
						alignItems="center"
						// justifyContent={
						// 	props.showMenu ? 'space-between' : 'flex-start'
						// }
						justifyContent="space-between"
						flexDirection="row"
						className="main-header-content"
					>
						<Link href={`/`} passHref legacyBehavior>
							<a>
								<Image
									src={getLogo(brandCode)}
									alt="logo"
									{...getLogoSize(brandCode)}
									priority
								/>
							</a>
						</Link>
						{showMenu ? <Menu /> : <Box />}
					</Content>
				</Tool>
			</AnimatedBar>
			{children ? (
				<Box pt={10} pb={2} px={2} mx="auto">
					{children}
				</Box>
			) : null}
			{/** this will add offset to bottom of toolbar to avoid hidding content */}
		</>
	);
};
export default Header;
