import React from 'react';
import { SxProps, Theme } from '@mui/material/styles';

import { BoxProps } from '@ui/components/Box';

import Header from '@web/components/app-layout/Header';
import Footer from '@web/components/layout/Footer';

import BrandCodeContext from '../BrandCodeContext';
import PageContent from './PageContent';
import { BrandCode } from '@interfaces/brand';

type Props = {
	showMenu?: boolean;
	showFooter?: boolean;
	hideBar?: boolean;
	noContainer?: boolean;
	contentColumn?: boolean;
	noFooterMargin?: boolean;
	usePx?: boolean;
	contentSx?: SxProps<Theme> | undefined;
	noRequestBtn?: boolean;
	initBrandCode?: BrandCode;
} & Omit<BoxProps, 'ref'>;

const PageWrapperPublic: React.FC<Props> = ({
	children = null,
	showMenu = true,
	noContainer = true,
	contentColumn = false,
	sx,
	contentSx,
	hideBar,
	showFooter,
	noFooterMargin,
	initBrandCode,
	noRequestBtn,
	...props
}) => {
	const brandCode = React.useContext(BrandCodeContext) || initBrandCode;
	return (
		<>
			{hideBar ? null : (
				<Header showMenu={showMenu} initBrandCode={initBrandCode} />
			)}
			<PageContent
				noContainer={noContainer}
				{...props}
				sx={sx}
				hiddenBar={hideBar}
				contentColumn={contentColumn}
				contentSx={contentSx}
				haveTopInfo={Boolean(brandCode)}
			>
				{children}
			</PageContent>
			{showFooter ? (
				<Footer
					noFooterMargin={noFooterMargin}
					noRequestBtn={noRequestBtn}
					initBrandCode={initBrandCode}
				/>
			) : null}
		</>
	);
};

export default PageWrapperPublic;
