import React from 'react';
import Head from 'next/head';

import { Page } from '@web/types';

import KYClinical from '@common-lib/modules/brands/screens/KYClinical';
import PageWrapperPublic from '@web/components/layout/PageWrapperPublic';
import { BrandCode } from '@interfaces/index';
import { GetStaticProps } from 'next';

const Root: Page<{
	brandCode: BrandCode;
}> = ({ brandCode }) => {
	return (
		<>
			<Head>
				<title>K-Y Clinical</title>
			</Head>
			<PageWrapperPublic
				pt={0}
				showMenu
				showFooter
				noFooterMargin
				noContainer
				noRequestBtn
			>
				<KYClinical noRequestBtn noContent />
			</PageWrapperPublic>
		</>
	);
};

export const getStaticProps: GetStaticProps<{
	brandCode: BrandCode;
}> = _ => {
	return Promise.resolve({
		props: { brandCode: BrandCode.KYCLINICAL, useKYGA: true },
	});
};
export default Root;
